import React, { Component } from "react";

export class testimonials extends Component {
  render() {
    return (
      <div id="testimonials">
        <div className="container">
          <div className="section-title text-center">
            <h2>NUESTROS CLIENTES</h2>
          </div>
          <div className="row">
            <div className="testimonial-content">
              <p>Nuestros clientes lo conforman: constructoras con situaciones especiales en algunos de sus proyectos en curso o requieren iniciar construcción optimizando recursos (materiales, equipos, personal, capital), proveedores y contratistas en búsqueda de verdaderas mejoras productivas y alianzas prácticas.</p>
              <div className="testimonial-meta">Por CONFIDENCIALIDAD, nuestros clientes prefieren el anonimato.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default testimonials;

import React, { Component } from "react";
import axios from 'axios';
export class Contact extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      mob: '',
      comment: '',
      success: false
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    const enquiryBody = { ...this.state };
    delete enquiryBody.success;
    axios({
      method: 'post',
      url: 'https://0khsqi413j.execute-api.us-east-1.amazonaws.com/production/',
      data: enquiryBody
    })
      .then(res => {
        if (res) this.setState({ success: true , firstName: "", email: "", comment: ""});

      })
      .catch(err => {
        console.log(err.message ? err.message : 'Unknown error');
      });
  };

  render() {
    const { firstName, lastName, email, mob, comment } = this.state;
    return (
      <div>
        <div id="contact">
          <div className="container">
          <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>CONTACTO:</h2>
                  <p>
                  Por favor completa la información a continuación, envía un correo electrónico y 
                  nos comunicaremos lo antes posible.
                  </p>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Nombre"
                          value={firstName}
                          onChange={e => this.setState({ firstName: e.target.value })}
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={e => this.setState({ email: e.target.value })}
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Mensaje"
                      value={comment}
                      onChange={e => this.setState({ comment: e.target.value })}
                      required
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  {this.state.success && <div id="success">
                    <div class='alert alert-success'>
                      <button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;</button>
                      <strong>Su correo se ha enviado exitosamente, estaremos atendiendo su solicitud.</strong>
                    </div>
                  </div>}
                  <button type="submit" className="btn btn-custom btn-lg">
                    Enviar Mensaje
                  </button>
        
                </form>
              
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Información de Contacto</h3>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Teléfono
                  </span>{" "}
                  {this.props.data ? this.props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {this.props.data ? this.props.data.email : "loading"}
                </p>
              </div>
              
            </div>
            
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2021 OscarCarreroIng. 
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;

import React, { Component } from "react";
import { FontAwesomeIcon as Fas} from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

export class Services extends Component {
  render() {
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>SERVICIOS INTEGRALES</h2>
            <p>
              CONSTRUCCIÓN Y SU CADENA DE VALOR
            </p>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div  key={`${d.name}-${i}`} className="col-md-4">
                    {" "}
                    <i className={d.icon}></i>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <h4>{d.name2}</h4>
                      <p>
                      <div className="right">
                      {d.datos.map((value, index) => {
                        return <p key={index}><Fas icon={faCaretRight} /> {value}</p>
                      })}
                      </div>
                      </p>                                           
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Services;

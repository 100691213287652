import React, { Component } from "react";

export class Header extends Component {
  render() {
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2 intro-text">
                    <h1>
                      {this.props.data ? this.props.data.title : "Loading"}
                      <span></span>
                    </h1>
                    <p>
                      {"SOLUCIONES PRÁCTICAS DE CONSTRUCCIÓN, A LA MEDIDA DE SU PROYECTO"}<br></br> 
                      <h2>
                        {"AUMENTAR utilidades"}<br></br>
                        {"DISMINUIR desperdicios y tiempos"}<br></br>
                        {"MEJORAR logística, procesos y calidad de vida de los trabajadores"}<br></br>
                      </h2>
                    </p>
                    <p>
                      {"RESOLUCIONES REALES AGILE (no teoría)"}<br></br> 
                      <h2>
                        {"Desde el 1er minuto somos parte de su empresa"}<br></br>
                      </h2></p>
                    <p>
                      {"APLICAMOS METODOLOGÍAS Y EXPERIENCIAS GANADORAS de otras industrias"}
                      <h2>
                        {"(Agile, NBA, Formula 1, LaLiga, Lean)"}
                      </h2>
                    </p>
                    <p>
                      {"COLABORAMOS CON TODA LA SINERGIA DE CONSTRUCCIÓN"}<br></br>
                    </p>
                    <p>
                      {"INTEGRAMOS EXPERIENCIA, INTELIGENCIA EMOCIONAL, INTELIGENCIA ARTIFICIAL"}
                    </p>
                    <a
                      href="#features"
                      className="btn btn-custom btn-lg page-scroll"
                    >
                      Conocer Más
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </header>
    );
  }
}

export default Header;
